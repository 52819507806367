import { oinkedit } from "../..";
import ConfigModifierComp from "./ConfigModifierComp";
export default class ConfigModifier{
    windowID :string;
    constructor(){
        this.windowID = oinkedit.current!.createWindow({
            children: <ConfigModifierComp />,
            height: 300,
            width: 500,
            top: 100,
            left: 200,
            label: "设置"
        });
    }
    close(){
        oinkedit.current!.removeWindow(this.windowID);
    }
}