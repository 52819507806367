import React, { Component as Cp } from "react";
import mainStyles from "../css/main.module.css";
import styles from "./Logo.module.css";
interface Props{
    size :string;
    title? :string;
}
export default class Logo extends Cp<Props>{
    render() :React.ReactNode{
        return(
            <div
                className={`${mainStyles.noselect} ${mainStyles.nodrag}`}
                title={this.props.title}
                style={{
                    width: this.props.size,
                    height: this.props.size
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 400 400"
                    className={styles.svg}>
                <path
                    d="M368.5 148.2c-2.4 1-52 20.3-90-9.8-6.1-4.8-34.3-28.5-30.4-64 3.1-28.9 25.4-44.7 28.2-46.6 20.2-14 50.3-17.9 73.8-2.2 32.9 22 26.7 65.4 26 69.4-3.9 24.8-20.4 41.3-28.2 48.8C308 182 195.3 265.2 40.9 368.4c51.1-67.9 192-258.7 182.8-296.7-.7-2.9-2.5-10.3-7.6-11.9-12.4-4-35.5 28.9-46.6 55.3-8.1 19.5-12.3 40.6-11.9 61.8"
                    fill="none"
                    stroke="#000"
                    strokeWidth="12"
                    strokeMiterlimit="10" />
                <path
                    d="M339 154.2c-1 .2-2.3.4-3.9.8-29.2 4.6-53.8-14.3-56.7-16.6-5.4-4.2-34.4-28-30.4-64 3.1-28.9 25.4-44.7 28.2-46.6 20.2-13.9 50.3-17.9 73.8-2.2 33 22.1 26.6 65.8 26 69.4-4 24.9-21.5 42.6-27.2 48.4-4.3 4.3-9 8.4-14.1 11.9"
                    fill="#3cf"
                    stroke="#000"
                    strokeWidth="12"
                    strokeMiterlimit="10" />
            </svg>
            </div>
        );
    }
}