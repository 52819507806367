import ls from "../utils/ls";
import en_US from "./en_US/en_US";
import zh_ac from "./zh_ac/zh_ac";
import zh_hans from "./zh_hans/zh_hans";
import zh_hant from "./zh_hant/zh_hant";
const data = {
    en_US,
    zh_hans,
    zh_hant,
    zh_ac
};
const i18n = {
    get current() :Immutable<typeof zh_hans>{ //zh_hans更新得比较快
        return (data as anyObject)[ls("i18n", "zh_hans")!] || data.zh_hans;
    },
    data
};
export default i18n;