import { css } from "./css";
import { windowDesc } from "../sections/window/WindowComp";
/**判断 `el1` 在 `el2` 上面还是下面 + 检测是否覆盖
 * 
 * 以 `el2` 为相对对象，`upper` 意为 el1 在 el2 上
*/
//export function tOrb(el1 :HTMLElement, el2 :HTMLElement){
//    return hwCoordtOrb({
//        top: rect(el1, "t"),
//        bottom: rect(el1, "b"),
//        left: rect(el1, "l"),
//        right: rect(el1, "r"),
//        zIndex: getZ(el1)
//    }, {
//        top: rect(el2, "t"),
//        bottom: rect(el2, "b"),
//        left: rect(el2, "l"),
//        right: rect(el2, "r"),
//        zIndex: getZ(el2)
//    }, true);
//    //let top1 = rect(el1, "t");
//    //let bottom1 = rect(el1, "b");
//    //let left1 = rect(el1, "l");
//    //let right1 = rect(el1, "r");
//    //let top2 = rect(el2, "t");
//    //let bottom2 = rect(el2, "b");
//    //let left2 = rect(el2, "l");
//    //let right2 = rect(el2, "r");
//    ////判断是否存在相互覆盖
//    ////fixed:不加等号会导致完全重叠在一起的窗口无法分离
//    //if(
//    //    (
//    //        (top1 >= top2 && top1 <= bottom2)
//    //     || (bottom1 >= top2 && bottom1 <= bottom2)
//    //     || (top2 >= top1 && top2 <= bottom1)
//    //     || (bottom2 >= top1 && bottom2 <= bottom1)
//    //    )
//    // && (
//    //        (left1 >= left2 && left1 <= right2)
//    //     || (right1 >= left2 && right1 <= right2)
//    //     || (left2 >= left1 && left2 <= right1)
//    //     || (right2 >= left1 && right2 <= right1)
//    //    )
//    //){
//    //    if(getZ(el1) > getZ(el2)) return "upper"; //a在上
//    //    else if(getZ(el1) < getZ(el2)) return "lower"; //a在下
//    //    else return "same"; //两个一样
//    //}else return "error"; //根本就没覆盖
//}
//#region 弃用代码
    //var hasSameZIndex = false;
    ////寻找是否存在同zIndex窗口，如没有则将上方每一个窗口都往下调，有则不管
    ////这段是为了弥补掉提升的窗口可能留下的空缺
    //for(let i = 0; i < tree.length; i++) if(tree[i].zIndex === originDesc.zIndex){
    //    hasSameZIndex = true;
    //    break;
    //}
    //if(hasSameZIndex) this.setState(state=>{
    //    const _windowArray = state.windowArray;
    //    for(let i = 0; i < state.windowArray.length; i++) if(state.windowArray[i].id === id) _windowArray[i] = {..._windowArray[i], zIndex: topZIndex};
    //    return{...state, windowArray: _windowArray};
    //});
    //else for(let i = 0; i < tree.length; i++) if(tree[i].zIndex > originDesc.zIndex){
    //    //规避副作用，先计算好zIndex
    //    const zIndex = tree[i].zIndex - 1;
    //    this.setState(state=>{
    //        const _windowArray = state.windowArray;
    //        for(let j = 0; j < state.windowArray.length; j++) if(state.windowArray[j].id === id) _windowArray[j] = {..._windowArray[j], zIndex};
    //        return{...state, windowArray: _windowArray};
    //    });
    //}
    ////把整棵树放回到zIndex50上去
    //var lowestZIndex = 2147483647;
    //for(let i = 0; i < tree.length; i++) lowestZIndex = Math.min(lowestZIndex, tree[i].zIndex);
    //if(lowestZIndex > 50){
    //    const d = lowestZIndex - 50;
    //    for(let i = 0; i < tree.length; i++){
    //        const zIndex = tree[i].zIndex - d;
    //        this.setState(state=>{
    //            const _windowArray = state.windowArray;
    //            for(let j = 0; j < state.windowArray.length; j++) if(state.windowArray[j].id === tree[i].id) _windowArray[j] = {..._windowArray[j], zIndex};
    //            return{...state, windowArray: _windowArray};
    //        });
    //    }
    //}
    //#endregion
/**返回两个窗口是否重叠
 * 
 * 以 `pel2` 为相对对象，`upper` 意为 pel1 在 pel2 上*/
export function isCovered(window1 :windowDesc, window2 :windowDesc){
    const coord1 = getRB(window1), coord2 = getRB(window2);
    //if(
    return(
            (coord1.top >= coord2.top && coord1.top <= coord2.bottom)
         || (coord1.bottom >= coord2.top && coord1.bottom <= coord2.bottom)
         || (coord2.top >= coord1.top && coord2.top <= coord1.bottom)
         || (coord2.bottom >= coord1.top && coord2.bottom <= coord1.bottom)
        )
     && (
            (coord1.left >= coord2.left && coord1.left <= coord2.right)
         || (coord1.right >= coord2.left && coord1.right <= coord2.right)
         || (coord2.left >= coord1.left && coord2.left <= coord1.right)
         || (coord2.right >= coord1.left && coord2.right <= coord1.right)
        );
    //){
        //if(el1.zIndex > el2.zIndex) return "upper"; //a在上
        //else if(el1.zIndex < el2.zIndex) return "lower"; //a在下
        //else return "same"; //两个一样
    //}else return "error"; //根本就没覆盖
    function getRB(el :windowDesc) :coord{
        return{
            top: el.top,
            left: el.left,
            bottom: el.top + el.height,
            right: el.left + el.width,
            //zIndex: el.zIndex
        };
    }
}
/**DOM 自身大小测量
 * 
 * 语法：`m`(argin)`b`(order)`p`(adding)`-`(横轴)`--`(加本体宽度)`|`(纵轴)`||`(加本体高度)`p` `b` `m`*/
export function dom(el :HTMLElement, magicPhrase :string) :number{
    let tempResult = 0;
    if(magicPhrase.includes("-")){ //横轴
        if(magicPhrase.includes("--")){ //需要本体宽度
            getWH("width");
            magicPhrase.replace("--", "-");
        }
        mbp(magicPhrase.substring(0, magicPhrase.indexOf("-")), "left");
        mbp(magicPhrase.substring(magicPhrase.indexOf("-") + 1, magicPhrase.length), "right");
    }
    else if(magicPhrase.includes("|")){ //纵轴
        if(magicPhrase.includes("||")){ //需要本体宽度
            getWH("height");
            magicPhrase.replace("||", "|");
        }
        mbp(magicPhrase.substring(0, magicPhrase.indexOf("|")), "top");
        mbp(magicPhrase.substring(magicPhrase.indexOf("|") + 1, magicPhrase.length), "bottom");
    }
    return tempResult;
    function mbp(data :string, p: string){
        if(data.includes("m")) tempResult += cssTrimPx("margin-" + p);
        if(data.includes("b")) tempResult += cssTrimPx("border-" + p + "-width");
        if(data.includes("p")) tempResult += cssTrimPx("padding-" + p);
    }
    function getWH(WHproperty :string){
        if(css(el, WHproperty) === "auto"){
            const clonedEl = el.cloneNode(true) as HTMLElement;
            css(clonedEl, {display: "block", position: "absolute", top: "-12914rem"});
            document.body.append(clonedEl);
            tempResult += cssTrimPx(WHproperty, clonedEl);
            document.body.removeChild(clonedEl);
        }
        else tempResult += cssTrimPx(WHproperty);
    }
    function cssTrimPx(property :string, detailedEl? :HTMLElement) :number{
        if(detailedEl === undefined) detailedEl = el;
        return parseFloat(css(detailedEl, property).replace("px", ""));
    }
}
/**DOM 坐标测量
 * 
 * 不带 `f` 的 magicLetter **仅用于**存在 tblr 属性的元素，其他元素请带上 `f`*/
export function rect(el :HTMLElement, magicLetter :"t" | "b" | "l" | "r" | "ft" | "fb" | "fl" | "fr") :number{
    switch(magicLetter){
        case "t": return fastCss("top");
        case "b": return rect(el, "t") + dom(el, "bp||pb");
        case "l": return fastCss("left");
        case "r": return rect(el, "l") + dom(el, "bp--pb");
        case "ft": return fix().t;
        case "fb": return rect(el, "ft") + dom(el, "bp||pb");
        case "fl": return fix().l;
        case "fr": return rect(el, "fl") + dom(el, "bp--pb");
        default: throw new SyntaxError("Incorrect magic letter");
    }
    function fix(){
        let t = el.offsetTop, l = el.offsetLeft, c = el.offsetParent as HTMLElement | null;
        while(c !== null){
            t += c.offsetTop - c.scrollTop;
            l += c.offsetLeft - c.scrollLeft;
            c = c.offsetParent as HTMLElement | null;
        }
        return {t, l};
    }
    function fastCss(property :string, detailedEl? :HTMLElement){
        if(detailedEl === undefined) detailedEl = el;
        return parseFloat(css(detailedEl, property).replace("px", ""));
    }
}