let canLeave = true;
export function startLeavePrompt(){
    window.addEventListener("beforeunload", e=>{
        //todo:控制是否提示用户不能关闭页面，通常用于正在保存或执行其他关键副作用
        if(!canLeave){
            e.preventDefault();
            e.returnValue = "";
            return "";
        }
    });
}
export function setCanLeave(){
    canLeave = true;
}
export function setCannotLeave(){
    canLeave = false;
}