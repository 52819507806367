import Dexie from "dexie";
import meta from "../dataProviders/meta";
import i18n from "../i18n";
import ls from "../utils/ls";
const l = localStorage;
const dataBase = new Dexie("oinkedit");
dataBase.version(1).stores({
    config: "key, value",
    blob: "++id"
});
dataBase.open();
export {dataBase};
export default async function iniStorage(){
    const config = dataBase.table("config");
    //todo:可能是更新，也可能是第一次加载，需code review
    if(l.getItem("initialized") !== meta.version){
        console.log("ini", meta);
    //#region 默认主题
        const defaultTheme = `
        --c-base: mintcream;
        --c-taskbar: beige;
        --c-menu: honeydew;
        --c-menu-1: antiquewhite;
        --c-menu-2: beige;
        --c-link: #0E73E5;
        --c-link-tra: #B7B0DFB9;
        --c-link-d: #164F96;
        --c-active: olive;
        --c-boxshadow: skyblue;
        --c-focus: dodgerblue;
        --c-warning :#F9DF82;
        --c-danger: #FF416C;
        --c-success: #27CE7F;
        --c-selection: #A1CDED;
        --c-text-menu: #191711;
        --c-text-main: #242322;
        --c-text-nav: black;
        --c-black-tra: #2423220F;
        --c-black-tra-1: #24232219;
        --c-black-tra-2: #24232223;
        --c-black-tra-3: #2423224A;
        --c-black-tra-4: #24232259;
        --c-black-tra-5: #2423226E;
        --c-1: #CEF6E3;
        --c-2: #17F18B;
        --c-3: #FFE8CF;
        --c-3-a: #FFECCC;
        --c-4: #FDFDC4;
        --c-4-a: #F4F3CC;
        --c-5: #CCFFEC;
        --c-5-a: #C9FCE9;
        --c-6: #FFD3D3;
        --c-6-a: #FFE4E4;
        --c-black: black;
        --c-grey--2: #444;
        --c-grey--1: dimgrey;
        --c-grey: grey;
        --c-grey-1: #989898;
        --c-grey-2: darkgrey;
        --c-grey-3: #AEAEAE;
        --c-grey-4: #BABABA;
        --c-grey-4-5: #CBCBCB;
        --c-grey-5: lightgrey;
        --c-grey-6: #DADADA;
        --c-grey-7: #EAEAEA;
        --c-grey-8: #EFEFEF;
        --c-overlay: #2228`.replaceAll(/\s*/g, "");
    //#endregion
        dataBase.table("config").bulkPut([
            {key: "book-list", value: []},
            {key: "book-history", value: []},
            {key: "themes", value: {default: defaultTheme}},
            {key: "windows", value: []},
        ]);
        l.setItem("initialized", meta.version);
        l.setItem("currentBook", "");
        l.setItem("currentTheme", "default");
    //#region 语言设置
        //todo:读取用户系统语言设置，没有对应语言则fallback到zh-hans
        const userLang = (navigator.language || (navigator as anyObject).browserLanguage).toLowerCase();
        if(userLang === "zh-cn") l.setItem("i18n", "zh_hans");
        else if(userLang === "zh-tw") l.setItem("i18n", "zh_hant");
    //#endregion
    //#region 自定义格言处理
        //hack:parseInt(null)===NaN，所以可以压缩代码，无需判null
        const textUpdatedTimes = parseInt(l.getItem("textEdited")!);
        //hack:NaN>0===false，所以可以压缩代码，无需判NaN
        if(!(textUpdatedTimes > 0)){
            l.setItem("text", i18n.current.taskbar.customText);
            l.setItem("textEdited", "0");
        }
    //#endregion
    }
    const theme = ls("currentTheme", "default")!, body = document.getElementById("body")!;
    body.setAttribute("style", body.getAttribute("style") || "" + (await config.get("themes")).value[theme]);
}