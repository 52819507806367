import React, { Component as Cp } from "react";
import styles from "./Resizer.module.css";
import mainStyles from "../../css/main.module.css";
import { disableContextMenu } from "../../utils/event";
export interface resizerAPIs{
    startResize :()=>void;
    updateResize :(height :number, width :number)=>void;
    endResize :()=>void;
    closeWindow :()=>void;
}
interface State{
    resizing :boolean;
    closing :boolean;
}
export default class Resizer extends Cp<resizerAPIs, State>{
    private originTop :number = 0;
    private originLeft :number = 0;
    private ref :React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    constructor(props :resizerAPIs){
        super(props);
        this.state = {resizing: false, closing: false};
    }
    private pointerDownCB = (event :React.PointerEvent<HTMLDivElement>)=>{
        if(event.isPrimary){
            document.addEventListener("pointermove", this.pointerMoveCB);
            document.addEventListener("pointerup", this.pointerUpCB);
            if(event.buttons === 1){
                this.setState({resizing: true});
                this.props.startResize();
                document.body.classList.add("resizing");
                this.originTop = event.clientY;
                this.originLeft = event.clientX;
            }
            else if(event.buttons === 2) this.setState({closing: true});
        }
    }
    private pointerMoveCB = (event :PointerEvent)=>{
        if(this.state.resizing) this.props.updateResize(event.clientY - this.originTop, event.clientX - this.originLeft);
        //避免在Resizer上按下右键再拖动到其他地方松开再hover Resizer出现的图标
        if(this.state.closing && event.buttons !== 2) this.setState({closing: false});
    }
    private pointerUpCB = (event :PointerEvent)=>{
        if(this.state.resizing){
            this.setState({resizing: false});
            this.props.endResize();
            document.body.classList.remove("resizing");
            document.removeEventListener("pointermove", this.pointerMoveCB);
            document.removeEventListener("pointerup", this.pointerUpCB);
        }
        else if(this.state.closing && event.target === this.ref.current!){
            event.preventDefault();
            disableContextMenu();
            this.setState({closing: false});
            this.props.closeWindow();
        }
    }
    render() :React.ReactNode{
        return(
            <div ref={this.ref} aria-hidden onPointerDown={this.pointerDownCB} className={`${styles.resizer} ${mainStyles.noselect} ${mainStyles.nodrag}${this.state.closing ? ` ${styles.closing}` :""}`}>◢</div>
        );
    }
}