import React, { Component as Cp } from "react";
import mainStyles from "../../css/main.module.css";
import ls from "../../utils/ls";
interface State{
    editing :boolean;
}
/**@once*/
export default class CustomText extends Cp<{}, State>{
    private ref = React.createRef<HTMLDivElement>();
    constructor(props :{}){
        super(props);
        this.state = {editing: false};
    }
    private dblClickCB = (event :React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
        if(!this.state.editing){
            this.setState({editing: true}, ()=>{
                this.ref.current!.focus();
                getSelection()!.selectAllChildren(this.ref.current!);
                document.addEventListener("keypress", this.keyDoneCB);
            });
        }
    }
    private keyDoneCB = (e :KeyboardEvent)=>{
        if(e.key === "Enter"){
            e.preventDefault();
            this.reset();
        }
    }
    private blurCB = ()=>{
        if(this.state.editing) this.reset();
    }
    private reset(){
        this.setState({editing: false});
        const lastText = ls("text"), thisText = this.ref.current!.innerText;
        ls("text", thisText, true);
        document.removeEventListener("keypress", this.keyDoneCB);
        getSelection()!.empty();
        if(lastText !== thisText) ls("textEdited", parseInt(ls("textEdited")!) + 1 + "", true);
    }
    render() :React.ReactNode{
        return(
            <div
                className={mainStyles.reselect}
                onDoubleClick={this.dblClickCB}
                onBlur={this.blurCB}
                contentEditable={this.state.editing}
                ref={this.ref}
                style={{height: "1rem", paddingBottom: ".25rem", whiteSpace: "pre"}}
            >
                {ls("text", "\u200d")}
            </div>
        );
    }
}