import React, { Component as Cp } from "react";
import { randoma2z029 } from "../../utils/general";
import Overlay, { overlayAPIs, overlayConstruct, overlayDesc } from "./Overlay";
type State = Immutable<{
    overlayArray :overlayDesc[];
}>;
/**@once*/
export default class OverlayManager extends Cp<{}, State>{
    constructor(props :{}){
        super(props);
        this.state = {overlayArray: []};
    }
//#region Overlay CRUD
    createOverlay(config :overlayConstruct) :string{
        const id = randoma2z029(15);
        this.setState(state=>{ return{
            overlayArray: [ ...state.overlayArray, { id,
                allowClose: config.allowClose ?? false,
                animate: config.animate ?? false,
                children: config.children ?? null,
                cover: config.cover ?? false,
                cursor: config.cursor ?? "", //""会被fallback到unset
                darken: config.darken ?? false,
                noWrapperUI: config.noWrapperUI ?? false,
                postClose: config.postClose ?? null,
                shouldClose: config.shouldClose ?? null
            }
        ]};});
        return id;
    }
    refreshOverlay(id :string, newConfig :Partial<overlayConstruct>) :boolean{
        let exists = false;
        for(let i = 0; i < this.state.overlayArray.length; i++) if(this.state.overlayArray[i].id === id){
            exists = true;
            this.setState(state=>this.bulkUpdateOverlayArray(state, id, {...newConfig}));
            break;
        }
        return exists;
    }
    removeOverlay(id :string) :boolean{
        let exists = false;
        for(let i = 0; i < this.state.overlayArray.length; i++) if(this.state.overlayArray[i].id === id){
            exists = true;
            this.setState(state=>{
                //避免副作用，如果直接用i，重复调用后就会多删一个
                const _overlayArray = [...state.overlayArray];
                for(let j = 0; j < state.overlayArray.length; j++) if(state.overlayArray[j].id === id) _overlayArray.splice(j, 1);
                return{overlayArray: _overlayArray};
            });
            break;
        }
        return exists;
    }
    getOverlayData(id :string) :overlayDesc | null{
        return this.getDescFromId(id) ?? null;
    }
//#endregion
//#region 工具方法
    /**得到的是state原对象
     * 
     * 使用第二个参数可以减少搜索复杂度*/
    private getDescFromId(id :string, descArray :Immutable<overlayDesc[]> = this.state.overlayArray) :overlayDesc | undefined{
        for(let i = 0; i < descArray.length; i++) if(descArray[i].id === id) return descArray[i];
    }
    /**是的，直接从窗口那边搬过来的
     * 基于 `overlayArray` 原有数据，批量更新 `overlayArray`。
     * @pure 不依赖于 `this.state`。
     * @returns 浅复制的 `overlayArray`（目标对象已被替换）。
     * @usage `this.setState(state=>bulkUpdateOverlayArray(state,id,{p:v}))`*/
    private bulkUpdateOverlayArray(state :State, id :string, config :Partial<overlayDesc>) :State{
        const newSnapShot = [...state.overlayArray];
        for(let i = 0; i < newSnapShot.length; i++) if(newSnapShot[i].id === id) newSnapShot[i] = {...newSnapShot[i], ...config};
        return{overlayArray: newSnapShot};
    }
//#endregion
/**WindowComp API 包装函数*/
    private getOverlayAPIs(id :string) :overlayAPIs{
        return{};
    }
    render() :React.ReactNode{
        const overlayRes = this.state.overlayArray.map(value=>{
            return <Overlay {...this.getOverlayAPIs(value.id)} {...value} key={value.id}>{value.children}</Overlay>
        });
        return(
            <div style={{zIndex: 12}}>{overlayRes}</div>
        );
    }
}