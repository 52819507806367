/**随机生成指定长度的a-zA-Z字符串*/
export function randoma2Z(length :number) :string{ //52
    let s :string = "";
    for(let i = 0; i < length; i++){
        let r = Math.floor(Math.random() * 52);
        if(r > 25) s += String.fromCharCode(r + 71);
        else s += String.fromCharCode(r + 65);
    }
    return s;
}
/**随机生成指定长度的a-z0-9字符串*/
export function randoma2z029(length :number) :string{ //36
    let s :string = "";
    for(let i = 0; i < length; i++){
        let r = Math.floor(Math.random() * 36);
        if(r < 10) s += r;
        else s += String.fromCharCode(r + 87);
    }
    return s;
}
/**判断是否是可转换为 JSON 文本的对象*/
export function isJSONObject(obj :object) :boolean{
    if(typeof obj == "object"){
        try{
            let str = JSON.stringify(obj);
            if(typeof str == "string" && str) return true;
            else return false;
        }catch(e){return false;}
    }
    return false;
}
/**数组去重*/
export function noRepeat<T>(arr :T[]) :T[]{
    return Array.from(new Set(arr));
}
/**交换数组中两个东西的值，可处理空属性数组，不改变数组长度
 * 
 * 会改变数组本身！
*/
export function exchange<T>(arr :T[], index1 :number, index2 :number) :void{
    const index1Exists = index1 in arr, index2Exists = index2 in arr;
    if(!index1Exists && !index2Exists) return;
    else{
        if(!index1Exists){
            const temp2 = arr[index2];
            arr[index1] = temp2;
            delete arr[index2];
            return;
        }
        if(!index2Exists){
            const temp1 = arr[index1];
            arr[index2] = temp1;
            delete arr[index1];
            
            return;
        }
        const temp = arr[index1];
        arr[index1] = arr[index2];
        arr[index2] = temp;
    }
}
/**递归冻结对象*/
export function constantize(obj :anyObject) :void{
    Object.freeze(obj);
    for(let i in obj) if(typeof obj[i] == "object") constantize(obj[i]);
}
/**将两个二维数组的第二维度按第一维度索引合到一起，支持中空数组
 * @returns 新数组，不更改原数组*/
export function combine<T>(arr1 :T[][], arr2 :T[][]) :T[][]{
    const totalLength = Math.max(arr1.length, arr2.length), result :T[][] = [];
    for(let i = 0; i < totalLength; i++){
        if(arr1[i]){
            if(result[i]) result[i] = [ ...result[i], ...arr1[i]];
            else result[i] = [...arr1[i]];
        }
        if(arr2[i]){
            if(result[i]) result[i] = [ ...result[i], ...arr2[i]];
            else result[i] = [...arr2[i]];
        }
    }
    return result;
}
/**拍平二维数组，支持中空数组*/
export function flat<T>(arr :T[][]) :T[]{
    let result :T[] = [];
    for(let i = 0; i < arr.length; i++) if(arr[i]) result = [...result, ...arr[i]];
    return result;
}
/**Not `JSON.stringify`, more than `JSON.stringify`.*/
export function advancedStringify(input :object) :string{
    //https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#%E5%BC%82%E5%B8%B8
    //用这个记录事后将不应该加双引号的类型去除双引号，避免如"undefined"（typeof string）和undefined（typeof undefined）都变成"undefined"的情况
    //const patchList :[string[], string[], object[]] = [[], [], []];
    if(input === null) return "null"; //不要{}了
    else{
        let result :string = "{";
        //我们只对元素本身的、可枚举的属性进行提取，即Object.keys得到的东西。
        const properties = Object.keys(input), toStringed = compatibleToString(input);
        if(properties.length == 0){
            if(toStringed != "[object Object]") return toStringed;
            else return "{}";
        }
        else{
            const input_ = decycle(input) as anyObject;
            for(let i = 0; i < properties.length; i++){
                const key = properties[i], value = input_[key], type = typeof value;
                if(type == "undefined" || type == "number" || type == "boolean") addResult(value as undefined | number | boolean);
                else if(type == "string") addResult(`"${value as string}"`); //字符串是带双引号的
                else if(type == "bigint") addResult((value as bigint) + "n");
                else if(type == "symbol") addResult((value as symbol).toString()); //symbol不能隐式转换为字符串，又不能用in运算符
                else if(type == "function") addResult(compatibleToString(value as Function));
                else if(type == "object") addResult(advancedStringify(value as object));
                else throw new TypeError("?"); //这个如果真走到了那世界可以毁灭了
                if(i < properties.length - 1) result += ", ";
                function addResult(input2 :any) :void{
                    result += `${key}: ${input2}`;
                }
            }
            result += "}";
            return result;
        }
    }
}
export function compatibleToString(input :object) :string{
    //typeof undefined不能in
    //typeof number™也不能in？？？那只能在前面兼容了
    return "toString" in input ? input.toString() : toString.call(input);
}
/* cycle.js 2021-05-31 Public Domain. NO WARRANTY EXPRESSED OR IMPLIED. USE AT YOUR OWN RISK. This code should be minified before deployment. See https://www.crockford.com/jsmin.html. USE YOUR OWN COPY. IT IS EXTREMELY UNWISE TO LOAD CODE FROM SERVERS YOU DO NOT CONTROL.*/
/**消除 JSON 对象中的循环引用*/
export function decycle(object :object) :object{
    const objects = new WeakMap<object, string>();
    return(function checkCycle(object :object, path :string){
        if( //过滤阴间object
            typeof object === "object"
         && object !== null
         && !(object instanceof Boolean)
         && !(object instanceof Date)
         && !(object instanceof Number)
         && !(object instanceof RegExp)
         && !(object instanceof String)
        ){
            const prev_path = objects.get(object);
            //如果在存储中已经有了对应的唯一值，那么就存在循环引用…………………………？，返回$ref：那个值的路径
            //fixed:仅当路径存在相同部分时，我们才能走回原点，这样才存在循环引用
            if(prev_path !== undefined && path.indexOf(prev_path) != -1) return {$ref: prev_path};
            else objects.set(object, path); //否则加入将这个值和路径加入存储
            //进入对象或数组，递归
            if(object instanceof Array){
                const newObj :any[] = [];
                for(let i = 0; i < object.length; i++) newObj[i] = checkCycle(object[i], path + "[" + i + "]");
                return newObj;
            }
            else{
                const newObj :anyObject = {}, keys = Object.keys(object);
                if(keys.length != 0) for(let i = 0; i < keys.length; i++) newObj[keys[i]] = checkCycle((object as anyObject)[keys[i]], path + "[" + JSON.stringify(keys[i]) + "]");
                else{
                    const toStringed = compatibleToString(object);
                    if(toStringed != "[object Object]") return object;
                    //else return newObj;
                }
                return newObj;
            }
        }
        else return object;
    }(object, "$"));
};