//#region React
import { useCallback } from "react";
//#endregion
//#region Radix-ui 导入
import * as Toggle from "@radix-ui/react-toggle";
import * as Icons from '@radix-ui/react-icons';
//#endregion
//#region remirror 导入
import { Extension, ExtensionPriority, uniqueId } from "remirror";
import { useHelpers, useKeymap, useEditorEvent, useActive, useCommands, useChainedCommands, EditorComponent, Remirror, useRemirror } from '@remirror/react';
//import { useRemirrorContext } from "@remirror/react-core";
import { AnnotationExtension, BoldExtension, BlockquoteExtension, BulletListExtension, CalloutExtension, CodeExtension, CodeBlockExtension, ColumnsExtension, DropCursorExtension, EmojiExtension, EntityReferenceExtension, EventsExtension, FontFamilyExtension, FontSizeExtension, GapCursorExtension, HeadingExtension, HistoryExtension, HorizontalRuleExtension, IframeExtension, ImageExtension, ItalicExtension, LinkExtension, ListItemExtension, ListItemSharedExtension, MarkdownExtension, NodeFormattingExtension, OrderedListExtension, PlaceholderExtension, PositionerExtension, ShortcutsExtension, StrikeExtension, SubExtension, SupExtension, TaskListExtension, TaskListItemExtension, TextCaseExtension, TextColorExtension, TextHighlightExtension, TrailingNodeExtension, UnderlineExtension } from 'remirror/extensions';
import { FindExtension } from '@remirror/extension-find';
//import '@remirror/styles/extension-gap-cursor.css';
//import 'remirror/styles/all.css';
import { TableExtension } from '@remirror/extension-react-tables';
//#endregion
//#region Oinkedit 模块引用
import i18n from "../../i18n";
import styles from "./ContentEditorComp.module.css";
//import icons from "../../css/icons.module.css";
import { getPx } from "../../utils/css";
//#endregion
export default function ContentEditorComp(props :{bookID :string}){
    const instance = useRemirror({
        extensions(){return[
            new LinkExtension(),
            new CodeExtension(),
            new AnnotationExtension(),
            new BoldExtension(),
            new BlockquoteExtension(),
            new BulletListExtension(),
            new CalloutExtension(),
            new CodeBlockExtension(),
            //这个目前有点小兼容问题，之后再开
            //new ColumnsExtension(),
            new DropCursorExtension({width: getPx(".1rem"), color: "var(--c-text-main)"}),
            //new EmojiExtension(),
            new EntityReferenceExtension(),
            new EventsExtension(),
            //new FindExtension(),
            new FontFamilyExtension(),
            new FontSizeExtension(),
            //有了TrailingNodeExtension就不需要这个
            //new GapCursorExtension(),
            new HeadingExtension(),
            new HistoryExtension(),
            new HorizontalRuleExtension(),
            new IframeExtension(),
            new ImageExtension({enableResizing: true,/* uploadHandler: (files :FileWithProgress[])=>{
                
            }*/}),
            new ItalicExtension(),
            new ListItemExtension(),
            new ListItemSharedExtension(),
            new MarkdownExtension(),
            new NodeFormattingExtension(),
            new OrderedListExtension(),
            new PlaceholderExtension({placeholder: i18n.current.contentEditor.placeholder}),
            new PositionerExtension(),
            //和notion一个德性乱改用户输入 //或许notion本来用的就是remirror？！
            //new ShortcutsExtension(),
            new StrikeExtension(),
            new SubExtension(),
            new SupExtension(),
            new TableExtension(),
            new TaskListExtension(),
            new TaskListItemExtension(),
            new TextCaseExtension(),
            new TextColorExtension(),
            new TextHighlightExtension(),
            new TrailingNodeExtension(),
            new UnderlineExtension(),
        ]}
    });
    const hooks = [
        ()=>{

        }
    ];
    /**@once*/
    function Menu(){
        const
            height = "2.15rem",
            width = "1.6rem",
            activeDetector = useActive(),
            chain = useChainedCommands(),
            {toggleBold, toggleItalic, toggleUnderline, toggleStrike, toggleCode} = useCommands();
        return(
            <div className={styles.menu}>
                <Toggle.Root
                    pressed={activeDetector.bold()}
                    className={styles.menuButton}
                    disabled={toggleBold.enabled() === false}
                    onClick={()=>chain.toggleBold().focus().run()}>
                    <Icons.FontBoldIcon />
                </Toggle.Root>
                <Toggle.Root
                    pressed={activeDetector.italic()}
                    className={styles.menuButton}
                    disabled={toggleItalic.enabled() === false}
                    onClick={()=>chain.toggleItalic().focus().run()}>
                    <Icons.FontItalicIcon />
                </Toggle.Root>
                <Toggle.Root
                    pressed={activeDetector.underline()}
                    className={styles.menuButton}
                    disabled={toggleUnderline.enabled() === false}
                    onClick={()=>chain.toggleUnderline().focus().run()}>
                    <Icons.UnderlineIcon />
                </Toggle.Root>
                <Toggle.Root
                    pressed={activeDetector.strike()}
                    className={styles.menuButton}
                    disabled={toggleStrike.enabled() === false}
                    onClick={()=>chain.toggleStrike().focus().run()}>
                    <Icons.StrikethroughIcon />
                </Toggle.Root>
                <Toggle.Root
                    pressed={activeDetector.code()}
                    className={styles.menuButton}
                    disabled={toggleCode.enabled() === false}
                    onClick={()=>chain.toggleCode().focus().run()}>
                    <Icons.CodeIcon />
                </Toggle.Root>
            </div>
        );
    }
    function MouseDownDetector(){
        useEditorEvent("mousedown", useCallback((event :MouseEvent)=>{
            console.log(event);
        }, []));
        return null;
    }
    return(
        <Remirror manager={instance.manager} hooks={hooks} classNames={[styles.main]}>
            <EditorComponent />
            {/*不会渲染其他东西的组件*/}
            {/*可能渲染其他东西的组件*/}
            <Menu />
        </Remirror>
    );
}