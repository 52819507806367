import React, { Component as Cp } from "react";
import mainStyles from "../css/main.module.css";
import styles from "./Oinkedit.module.css";
import meta from "../dataProviders/meta";
import Background from "./background/Background";
import MenuOpener from "./taskbar/MainMenu";
import CustomText from "./taskbar/CustomText";
import TimeMaster from "./taskbar/TimeMaster";
import WindowComp, { windowDesc, windowAPIs, windowConstruct } from "./window/WindowComp";
import { exchange, flat, randoma2z029 } from "../utils/general";
import Task, { taskAPIs } from "./taskbar/Task";
import OverlayManager from "./overlay/OverlayManager";
import { checkPos, getAttachedStatus, getPx, getTaskBarHW, getWindowPadding } from "../utils/css";
import { combine } from "../utils/general";
import { isCovered } from "../utils/zindex";
import i18n from "../i18n";
import { rootEl } from "..";

const overlay = React.createRef<OverlayManager>();
export {overlay};
export_({overlay});
type Props = Immutable<{}>;
type State = Immutable<{
    windowArray :windowDesc[];
    taskMoving :boolean;
}>;
/**主组件
 * @once*/
export default class Oinkedit extends Cp<Props, State>{
    private taskMove_currentHoveredID? :string;
    constructor(props :Props){
        super(props);
        //todo:获取工作区保存数据
        this.state = {windowArray: [], taskMoving: false};
    }
//#region 生命周期钩子
    componentDidMount(){
        this.resizeObserver.observe(rootEl);
    }
    componentWillUnmount(){
        this.resizeObserver.unobserve(rootEl);
        this.resizeObserver.disconnect();
    }
//#endregion
//#region 原生 DOM API 相关
    private resizeCB = (entries :ResizeObserverEntry[])=>{
        for(let i = 0; i < entries.length; i++){
            //正常情况下只会有一个，但我害怕不正常情况
            if(entries[i].target === rootEl) for(let i = 0; i < this.state.windowArray.length; i++){
                const
                    {top: oldTop, left: oldLeft, height: oldHeight, width: oldWidth} = this.state.windowArray[i],
                    {top, left, height, width} = checkPos({top: oldTop, left: oldLeft}, {height: oldHeight, width: oldWidth});
                this.setState(state=>this.bulkUpdateWindowArray(state, state.windowArray[i].id, {top, left, height, width, ...getAttachedStatus({top, left, height, width})}));
            }
            break;
        }
    }
    private resizeObserver :ResizeObserver = new ResizeObserver(this.resizeCB);
//#endregion
//#region Window CRUD
    /**公开 API 中永远不要期望类型正确。*/
    createWindow(config :windowConstruct, cb? :()=>void) :string{
        const
            id = randoma2z029(15), hw = getTaskBarHW(), padding = getWindowPadding(),
            top = config.top && config.top >= hw.height ? config.top : hw.height,
            left = config.left && config.left >= hw.width ? config.left : hw.width,
            //优雅降级：只显示 padding + 2rem
            //fixed:必须使用 `||` 来将 0 干掉！
            height = config.height || padding.tb * 2 + getPx("2rem"),
            width = config.width || padding.lr * 2 + getPx("2rem");
            //console.log(top,left,height,width);
        this.setState(state=>{return{ ...state, windowArray: [...state.windowArray, {
                label: config.label ?? i18n.current.taskbar.noLabel,
                children: config.children ?? null,
                top, left, height, width, id,
                zIndex: this.getTopZIndex(this.state.windowArray, id),
                hoveredInTask: false, minimized: false, focusingInWindow: false,
                noWrapperUI: config.noWrapperUI ?? false,
                ...getAttachedStatus({top, left, height, width})
            }
        ]};}, cb);
        return id;
    }
    refreshWindow(id :string, newConfig :Partial<windowConstruct>, cb? :()=>void) :boolean{
        let exists = false;
        for(let i = 0; i < this.state.windowArray.length; i++) if(this.state.windowArray[i].id === id){
            exists = true;
            this.setState(state=>this.bulkUpdateWindowArray(state, id, {...newConfig}), cb);
            break;
        }
        return exists;
    }
    removeWindow(id :string, cb? :()=>void) :boolean{
        let exists = false;
        for(let i = 0; i < this.state.windowArray.length; i++) if(this.state.windowArray[i].id === id){
            exists = true;
            this.setState(state=>{
                //避免副作用，如果直接用i，重复调用后就会多删一个
                const _windowArray = [...state.windowArray];
                for(let j = 0; j < state.windowArray.length; j++) if(state.windowArray[j].id === id) _windowArray.splice(j, 1);
                return{...state, windowArray: _windowArray};
            }, cb);
            break;
        }
        return exists;
    }
    getWindowData(id :string) :windowDesc | null{
        return this.getDescFromId(id) ?? null;
    }
//#endregion
//#region 通用工具方法
    /**得到的是state原对象
     * 
     * 使用第二个参数可以减少搜索复杂度*/
    private getDescFromId(id :string, descArray :Immutable<windowDesc[]> = this.state.windowArray) :windowDesc | undefined{
        for(let i = 0; i < descArray.length; i++) if(descArray[i].id === id) return descArray[i];
    }
    /**基于 `windowArray` 原有数据，更新 `windowArray`。只能一次更新一个属性。
     * @pure 不依赖于 `this.state`。
     * @returns 浅复制的 `windowArray`（目标对象已被替换）。
     * @usage `this.setState(state=>updateWindowArray(state,id,p,v))`*/
    private updateWindowArray<T extends keyof windowDesc>(state :State, id :string, property :T, value :windowDesc[T]) :State{
        const newSnapShot = [...state.windowArray];
        for(let i = 0; i < newSnapShot.length; i++) if(newSnapShot[i].id === id) newSnapShot[i] = {...newSnapShot[i], [property]: value};
        return{...this.state, windowArray: newSnapShot};
    }
    /**基于 `windowArray` 原有数据，批量更新 `windowArray`。
     * @pure 不依赖于 `this.state`。
     * @returns 浅复制的 `windowArray`（目标对象已被替换）。
     * @usage `this.setState(state=>this.bulkUpdateWindowArray(state, id, {p: v}))`*/
    private bulkUpdateWindowArray(state :State, id :string, config :Partial<windowDesc>) :State{
        const newSnapShot = [...state.windowArray];
        for(let i = 0; i < newSnapShot.length; i++) if(newSnapShot[i].id === id) newSnapShot[i] = {...newSnapShot[i], ...config};
        return{...this.state, windowArray: newSnapShot};
    }
//#endregion
//#region zIndex 工具方法
    private setZIndex(id :string, zIndex :number, cb? :()=>void){
        //console.log(`${id} => ${zIndex}`);
        this.setState(state=>this.updateWindowArray(state, id, "zIndex", zIndex));
    }
    /**得到的直接是最顶层，无需再+1；最顶层与这个窗口无关，最终层数可能反而往下调
     * 
     * 不会更改传入的Array*/
    private getTopZIndex(descArray :Immutable<windowDesc[]>, id :string) :number{
        let result = 50;
        //专门排除自己
        for(let i = 0; i < descArray.length; i++) if(descArray[i].id !== id) result = Math.max(result, descArray[i].zIndex + 1);
        //console.log(`${id} =TOP=> ${result} in`, descArray);
        return result;
    }
    /**从一个元素开始，得到堆叠的整个窗口集合
     * @returns 浅复制的 `windowArray` 子集。*/
    private getWholeZMap(id :string, descArray :Immutable<windowDesc[]> = this.state.windowArray) :windowDesc[][] | undefined{
        //fixed:用windowDesc做主键，会遇到复制后的对象被认为不同主键而被多次存储的问题！
        const windowArray = [...descArray], processedWindows = new Map<string, true>(), desc = this.getDescFromId(id);
        if(desc !== undefined) return dfsGetZMap(desc);
        function dfsGetZMap(desc :windowDesc) :windowDesc[][]{
            let result :windowDesc[][] = [];
            processedWindows.set(desc.id, true);
            const thisIndex = desc.zIndex - 50;
            if(result[thisIndex] !== undefined) result[thisIndex].push(desc);
            else result[thisIndex] = [desc];
            for(let i = 0; i < windowArray.length; i++) if(processedWindows.get(windowArray[i].id) !== true && isCovered(desc, windowArray[i])) result = combine(result, dfsGetZMap(windowArray[i]));
            return result;
        }
    }
    /**只获取直接堆叠的窗口集合*/
    private getLocalZMap(id :string, descArray :Immutable<windowDesc[]> = this.state.windowArray) :windowDesc[][] | undefined{
        const windowArray = [...descArray], desc = this.getDescFromId(id), result :windowDesc[][] = [];
        if(desc !== undefined){
            for(let i = 0; i < windowArray.length; i++) if(isCovered(desc, windowArray[i])){
                const _thisZIndex = windowArray[i].zIndex - 50;
                if(result[_thisZIndex]) result[_thisZIndex].push(windowArray[i]);
                else result[_thisZIndex] = [windowArray[i]];
            }
            return result;
        }
    }
    /**得到的是将窗口往下移得到的最小zIndex，就是物理掉落
     * 
     * 第二个参数是查找范围，可以传入新的zIndex的缓存，避免state延迟更新*/
    private getMinAcceptableZIndex(desc :Immutable<{zIndex :number, id :string}>, _zIndexMap? :windowDesc[][]) :number | undefined{
        const startIndex = desc.zIndex - 50, zIndexMap = _zIndexMap ?? this.getLocalZMap(desc.id);
        if(zIndexMap){
            for(let i = startIndex - 1; i >= 0; i--) if(zIndexMap[i]) return i + 50 + 1;
            return 50;
        }
    }
//#endregion
//#region Window API
    /**WindowComp API 包装函数*/
    private getWindowAPIs(id :string) :windowAPIs{
        return{
            requestToTop: this.goToGlobalTop.bind(this, id),
            requestZIndex: this.zIndex.bind(this, id),
            requestFallZIndex: this.fallZIndex.bind(this, id),
            requestUpdateAttachedStatus: this.requestUpdateAttachedStatus.bind(this, id),
            updateTL: this.updateTL.bind(this, id),
            onFocus: this.onFocus.bind(this, id),
            onUnFocus: this.onUnFocus.bind(this, id),
            resizerAPIs: {
                startResize: this.startResize.bind(this, id),
                updateResize: this.updateResize.bind(this, id),
                endResize: this.endResize.bind(this, id),
                closeWindow: this.closeWindow.bind(this, id),
            },
        };
    }
    private goToGlobalTop(id :string){
        this.setZIndex(id, this.getTopZIndex(this.state.windowArray, id));
    }
    /**大名鼎鼎的zIndex，来自因Oinkedit而生的项目mwds(放在wheelsmake账户的私有存档项目)。
     * 
     * 谨以此注释纪念Oinkedit的历史。*/
    private zIndex(id :string) :void{
        const globalZIndexMap = this.getWholeZMap(id);
        //console.log(`zIndex: ${id}`, globalZIndexMap);
        if(globalZIndexMap){
            //fixed:globalZIndexMap可能是中空数组，不能直接用length属性得到成员数量
            if(Object.keys(globalZIndexMap).length > 1){
                const thisWindowDesc = this.getDescFromId(id, flat(globalZIndexMap));
                if(thisWindowDesc){
                    //如果窗口所在层有其他窗口，直接将窗口移动到最顶层即可
                    const thisIndex = thisWindowDesc.zIndex - 50;
                    //console.log(globalZIndexMap[thisIndex]);
                    if(globalZIndexMap[thisIndex].length > 1) this.setZIndex(id, globalZIndexMap.length + 50);
                    else{ //否则将上面每一个窗口往下减1（先将这个窗口移动到最顶层，防止边缘问题导致视图闪烁）
                        //这里是移动到和顶层窗口一致的高度，所以要减去length多加的1，+49
                        this.setZIndex(id, globalZIndexMap.length + 49);
                        //遍历这个窗口以上的所有窗口，减1
                        for(let i = thisIndex + 1; i < globalZIndexMap.length; i++){
                            if(globalZIndexMap[i]) for(let j = 0; j < globalZIndexMap[i].length; j++){
                                this.setZIndex(globalZIndexMap[i][j].id, globalZIndexMap[i][j].zIndex - 1);
                            }
                        }
                    }
                    //this.fallZIndex(id);
                }
                //else //不管了，一大堆边界情况
            }
            else this.setZIndex(id, 50); //如果一切正常，那么这里就是这个窗口，我们把它拉回50层
        }
    }
    /**挤掉层级关系中的空层
     * 
     * 原来只针对于一个窗口，现在则是针对于这个窗口所在的子集*/
    private fallZIndex(id :string) :void{
        const wholeZIndexMap = this.getWholeZMap(id);
        //console.log(`fallZIndex: ${id}`, wholeZIndexMap);
        if(wholeZIndexMap){
            //fuck:这里必须加大括号！不加的话可读性低到想打人！
            if(Object.keys(wholeZIndexMap).length > 1){
                //它们可以共用成员，因为成员本来就是state里的东西
                //这个东西的唯一目的就是避免更改wholeZIndexMap造成不稳定for循环
                const zIndexMapLive :windowDesc[][] = [];
                for(let i = 0; i < wholeZIndexMap.length; i++) if(wholeZIndexMap[i]) zIndexMapLive[i] = [...wholeZIndexMap[i]];
                for(let i = 1; i < wholeZIndexMap.length; i++){
                    if(wholeZIndexMap[i]) for(let j = 0; j < wholeZIndexMap[i].length; j++){
                        const thisDesc = wholeZIndexMap[i][j],
                              //不应该在整个zIndexMapLive中查找最低层数，应在本地窗口的最新数据中查找
                              newZIndex = this.getMinAcceptableZIndex(thisDesc, this.getLocalZMap(thisDesc.id, flat(zIndexMapLive)))!,
                              newIndex = newZIndex - 50;
                        //console.log(thisDesc.id, newZIndex, zIndexMapLive);
                        //将旧的对象从live中删除……
                        zIndexMapLive[thisDesc.zIndex - 50].splice(zIndexMapLive[thisDesc.zIndex - 50].indexOf(thisDesc));
                        //并移动到新的zIndex索引处
                        if(zIndexMapLive[newIndex]) zIndexMapLive[newIndex].push(thisDesc);
                        else zIndexMapLive[newIndex] = [thisDesc];
                        this.setZIndex(thisDesc.id, newZIndex);
                    }
                }
            }
            else this.setZIndex(id, 50);
        }
    }
    private requestUpdateAttachedStatus(id :string){
        const desc = this.getDescFromId(id);
        if(desc){
            const {top, left, height, width} = desc;
            this.setState(state=>this.bulkUpdateWindowArray(state, id, {...getAttachedStatus({top, left, height, width})}));
        }
    }
    private updateTL(id :string, top :number, left :number){
        this.setState(state=>this.bulkUpdateWindowArray(state, id, {top, left}));
    }
    private onFocus(id :string){
        this.setState(state=>this.updateWindowArray(state, id, "focusingInWindow", true));
    }
    private onUnFocus(id :string){
        this.setState(state=>this.updateWindowArray(state, id, "focusingInWindow", false));
    }
    //#region Resizer API
    private startResize(id :string){
        this.goToGlobalTop(id);
    }
    private updateResize(id :string, height :number, width :number){
        this.setState(state=>this.bulkUpdateWindowArray(state, id, {
            height, width
        }));
    }
    private endResize(id :string){
        this.fallZIndex(id);
    }
    private closeWindow(id :string){
        //todo:给窗口发信号，使其做好销毁准备
        //argument:到底需不需要？窗口内部的应用才是关键，它才需要做准备，但它完全可以用生命周期方法得到信号
        console.log(`${id} close`);
        this.removeWindow(id);
    }
    //#endregion
//#endregion
//#region Task API
    /**Task API 包装函数*/
    private getTaskAPIs(id :string) :taskAPIs{
        return{
            hideWindow: this.hideWindow.bind(this, id),
            showWindow: this.showWindow.bind(this, id),
            hoverInTask: this.hoverInTask.bind(this, id),
            dehoverInTask: this.dehoverInTask.bind(this, id),
            startMove: this.startMove.bind(this, id),
            endMove: this.endMove.bind(this, id),
        };
    }
    private hideWindow(id :string){
        this.setState(state=>this.updateWindowArray(state, id, "minimized", true));
    }
    private showWindow(id :string){
        this.setState(state=>this.updateWindowArray(state, id, "minimized", false), ()=>{
            this.goToGlobalTop(id);
        });
        //this.zIndex(id);
    }
    private hoverInTask(id :string){
        if(!this.state.taskMoving) this.setState(state=>this.updateWindowArray(state, id, "hoveredInTask", true));
        else this.taskMove_currentHoveredID = id;
    }
    private dehoverInTask(id :string){
        this.setState(state=>this.updateWindowArray(state, id, "hoveredInTask", false));
    }
    private startMove(id :string){
        this.setState({taskMoving: true});
        this.taskMove_currentHoveredID = id;
    }
    private endMove(id :string){
        this.setState({taskMoving: false});
        //如果currentHoveredID是空的，就跳过任务位置的交换
        if(this.taskMove_currentHoveredID !== undefined){
            let localIDIndex :number = 0, targetIDIndex :number = 0;
            //交换任务位置
            for(let i = 0; i < this.state.windowArray.length; i++){
                if(this.state.windowArray[i].id === this.taskMove_currentHoveredID) targetIDIndex = i;
                if(this.state.windowArray[i].id === id) localIDIndex = i;
            }
            //console.log(this.taskMove_currentHoveredID, localIDIndex, targetIDIndex);
            //如果在自身上面停下，那么就直接跳过
            if(localIDIndex !== targetIDIndex){
                //endMove无法被频繁调用，基本不存在漏state问题，并且交换state数组元素位置的操作是必定产生副作用的操作
                //因此我们不使用函数来调用setState而是直接给新值
                const _windowArray = [...this.state.windowArray];
                exchange(_windowArray, localIDIndex, targetIDIndex);
                this.setState({windowArray: _windowArray});
            } //清除，避免对下一次造成影响
            this.taskMove_currentHoveredID = undefined;
        }
    }
//#endregion
//#region Render
    render() :React.ReactNode{
        const
            windowRes = this.state.windowArray.map(value=>{
                const {label, ...valueWithoutLabel} = value;
                return <WindowComp {...this.getWindowAPIs(value.id)} {...valueWithoutLabel} key={value.id}>{value.children}</WindowComp>
            }),
            taskRes = this.state.windowArray.map(value=>{
                const {label, focusingInWindow, minimized, children} = value, props = {label, focusingInWindow, minimized, children};
                return <Task {...this.getTaskAPIs(value.id)} taskMoving={this.state.taskMoving} {...props} key={value.id} />
            });
        return(<>
            {/*window容器*/}<div style={{zIndex: 6, color: "var(--c-text-main)"}}>{windowRes}</div>
            <div className={`${mainStyles.noselect} ${styles.taskbar}`}>
                <MenuOpener />
                <ul className={styles.tasks} style={{
                    opacity: this.state.taskMoving ? .6 : "unset",
                }}>{taskRes}</ul>
                <div className={styles.right}>
                    <CustomText />
                    <TimeMaster />
                </div>
            </div>
            {/*overlay*/}<OverlayManager ref={overlay} />
            <Background />
        </>);
    }
//#endregion
}
//DEV ONLY
export function export_(arg :anyObject){
    if(meta.dev === true) for(let i in arg) (window as anyObject)[i] = arg[i];
}