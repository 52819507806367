import { advancedStringify } from "../utils/general";
import styles from "./VerticalList.module.css";
type Props = Immutable<{
    styles? :React.CSSProperties;
    children :React.ReactNode[];
}>;
export default function VerticalList(props :Props){
    return(<ul className={styles.ul} style={props.styles}>
        {props.children.map(value=><li className={styles.li} key={advancedStringify({value})}>{value}</li>)}
    </ul>);
}