import { oinkedit } from "../..";
import ContentEditorComp from "./ContentEditorComp";
import "./ContentEditor.css";
export default class ContentEditor{
    windowID :string;
    constructor(bookID :string){
        this.windowID = oinkedit.current!.createWindow({
            children: <ContentEditorComp bookID={bookID} />,
            height: 300,
            width: 500,
            top: 100,
            left: 200,
            label: `编辑 ${bookID}`
        });
    }
    close(){
        oinkedit.current!.removeWindow(this.windowID);
    }
}