const en_US = {
    taskbar: {
        customText: "Double click here to edit motto",
        timeMaster: {
            title: "Task Management"
        },
        noLabel: "Untitled",
    },
    contentEditor: {
        placeholder: "Start your story..."
    },
    start: {
        titleTip: "Menu",
    },
    background: {
        tips: [
            <div key="menu">Open main menu　　Click on the logo in the left of taskbar</div>,
            <div key="external">Open external files　　<kbd>Ctrl</kbd> + <kbd>O</kbd></div>,
        ],
    },
    errors: {
        rootElNotFound: {
            main: "Cannot find the root HTML DOM element used to mount the app.",
            causedByOriginEl: "Cannot find an element with id `oinkedit` in the document. If you use a custom element, please confirm that `window.oeRoot` is set as the ID of the element.",
            causedByCustomEl: "Cannot find the custom element in the document. If you do not use a custom element, please take a look at `window.oeRoot`."
        },
        windowGeneric: "The app inside this window had encountered an error and crashed. Try restarting this app.",
        overlayGeneric: "An error occured. Try reopening the popup.",
        intrinsicApps: {
            indexer: {
                noSuchBook: "No books matched with specific ID."
            }
        }
    },
    debug: {
        skipSW_Electron: "Skipped Service Worker registration in Electron environment",
        skipSW_Dev: "Skipped Service Worker registration in development environment",
        lipsum_crash: "On-purpose crash. It's NOT a bug",
    },
} as const;
export default en_US;