/**有第二个参数就不可能null */
export default function ls(key :string, defaultValueOrData? :string, isSet? :boolean) :string | null{
    if(isSet){
        localStorage.setItem(key, defaultValueOrData!);
        return defaultValueOrData!;
    }
    else{
        if(defaultValueOrData !== undefined) return localStorage.getItem(key) ?? (localStorage.setItem(key, defaultValueOrData), defaultValueOrData);
        else return localStorage.getItem(key);
    }
}