import React, { Component as Cp } from "react";
import Logo from "../../generalComponents/Logo";
import mainStyles from "../../css/main.module.css";
import VWSpace from "../../generalComponents/VWSpace";
import i18n from "../../i18n";
import meta from "../../dataProviders/meta";
/**@once*/
export default class Background extends Cp{
    private contextMenuCB = (event :React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
        event.preventDefault();
    }
    render() :React.ReactNode{
        return(
            <div style={{
                height: "calc(100vh - var(--cf-tbtaskbar))",
                width: "100vw",
                textAlign: "center",
                display: "flex",
                flexFlow: "column nowrap",
                alignItems: "center",
                color: "var(--c-grey-2)",
                backgroundColor: "var(--c-base)",
                zIndex: 0
            }} className={`${mainStyles.noselect} ${mainStyles.nodrag}`} onContextMenu={this.contextMenuCB}>
                <VWSpace height="6vh" />
                <div style={{opacity: .25}}><Logo size="10rem" /></div>
                <VWSpace height="3vh" />
                <div style={{fontSize: ".9rem"}}>{i18n.current.background.tips}</div>
                <VWSpace height="8vh" />
                <div>Oinkedit&nbsp;&nbsp;{meta.version}</div>
                <VWSpace height="1vh" />
                <div>{meta.themePoem}</div>
            </div>
        );
    }
}