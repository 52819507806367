const zh_hans = {
    taskbar: {
        customText: "双击此处编辑格言",
        timeMaster: {
            title: "任务管理",
        },
        noLabel: "无标题",
    },
    contentEditor: {
        placeholder: "开始创作……",
        //实验性内容。
        //menu: {
        //    toggleBold: "切换加粗"
        //}
    },
    start: {
        titleTip: "菜单",
    },
    background: {
        tips: [
            <div key="menu">打开主菜单　　单击任务栏左侧 Logo</div>,
            <div key="external">打开外部文件　　<kbd>Ctrl</kbd> + <kbd>O</kbd></div>,
        ],
    },
    errors: {
        rootElNotFound: {
            main: "找不到用于挂载应用的根 HTML DOM 元素。",
            causedByOriginEl: "无法在文档中找到 ID 为 `oinkedit` 的元素。如果您使用自定义元素，请确认是否将 `window.oeRoot` 设置为该元素的 ID。",
            causedByCustomEl: "无法在文档中找到自定义元素。如果您不使用自定义元素，请确认 `window.oeRoot` 是否被污染。"
        },
        windowGeneric: "该窗口中的应用出现了一个错误并已崩溃。尝试重新启动这个应用。",
        overlayGeneric: "出现了一个错误。尝试重新打开弹窗。",
        intrinsicApps: {
            indexer: {
                noSuchBook: "未找到该 ID 对应的书籍。",
            },
        },
    },
    debug: {
        skipSW_Electron: "桌面版，跳过 Service Worker 注册",
        skipSW_Dev: "开发模式，跳过 Service Worker 注册",
        lipsum_crash: "人为崩溃，无需理会此错误",
    },
} as const;
export default zh_hans;