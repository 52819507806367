import React, { Component as Cp, ErrorInfo } from "react";
import { checkPos, getTaskBarHW } from "../../utils/css";
import DebugPanel from "./DebugPanel";
import Resizer, { resizerAPIs } from "./Resizer";
import styles from "./WindowComp.module.css";
import i18n from "../../i18n";
import globalState from "../../dataProviders/globalState";
/**基础类型*/
interface windowBase{
    top? :number;
    left? :number;
    height :number;
    width :number;
    children :React.ReactNode;
    /**是否使用默认的窗口包装子元素*/
    noWrapperUI? :boolean;
}
/**创建新的windowComp时需要向父组件传入的参数*/
export interface windowConstruct extends windowBase{
    label :string;
}
/**需要传给windowComp本身的参数*/
type windowConfig = Required<windowBase> & {
    id :string;
    zIndex :number;
    minimized :boolean;
    hoveredInTask :boolean;
    focusingInWindow :boolean;
    //贴附状态
    attached_t :boolean;
    attached_b :boolean;
    attached_l :boolean;
    attached_r :boolean;
}
/**窗口总描述对象*/
export type windowDesc = Immutable<windowConfig & {label :string;}>;
export interface windowAPIs{
    requestToTop :()=>void;
    requestZIndex :()=>void;
    requestFallZIndex :()=>void;
    requestUpdateAttachedStatus :()=>void;
    updateTL :(top :number, left :number)=>void;
    onFocus :()=>void;
    onUnFocus :()=>void;
    resizerAPIs :resizerAPIs;
}
type windowProps = Immutable<windowAPIs & windowConfig>;
type State = Immutable<{
    //基本状态
    onError :boolean;
    hovered :boolean;
    moving :boolean;
    focusingWithIn :boolean;
}>;
export default class WindowComp extends Cp<windowProps, State>{
    private deltaTop :number = 0;
    private deltaLeft :number = 0;
    private outerDivRef :React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    private resizing :boolean = false;
    private resizeOriginHeight :number = 0;
    private resizeOriginWidth :number = 0;
    constructor(props :windowProps){
        super(props);
        //兼容不传top或left，默认左上角
        const hw = getTaskBarHW(), checkedBox = checkPos({
            top: props.top,
            left: props.left
        }, {
            height: props.height,
            width: props.width
        });
        //用经过验证的TL刷新主组件数据
        props.updateTL(checkedBox.top, checkedBox.left);
        this.state = {
            moving: false, focusingWithIn: false, hovered: false, onError: false
        };
    }
//#region 错误边界
    //吐血的是，这里ts不能支持类型了，我们得翻文档写
    static getDerivedStateFromError(nextProps: Readonly<windowProps>, prevState: State) :Partial<State> | null{
        return{onError: true};
    }
    componentDidCatch(error :Error, errorInfo :ErrorInfo){
        console.error(error, errorInfo);
    }
//#endregion
//#region Resizer 方法拦截
    private startResize = ()=>{
        this.props.resizerAPIs.startResize();
        this.resizing = true;
        this.resizeOriginHeight = this.props.height;
        this.resizeOriginWidth = this.props.width;
    }
    private checkPos_updateAttachWhileResize = (deltaHeight :number, deltaWidth :number)=>{
        const checkedBox = checkPos({
                top: this.props.top,
                left: this.props.left
            }, {
                height: this.resizeOriginHeight + deltaHeight,
                width: this.resizeOriginWidth + deltaWidth
            }, true);
        this.props.resizerAPIs.updateResize(checkedBox.height, checkedBox.width);
        this.props.requestUpdateAttachedStatus();
    }
    private endResize = ()=>{
        this.props.resizerAPIs.endResize();
        this.resizing = false;
    }
//#endregion
//#region 窗口移动逻辑 & 按下窗口的其他逻辑
    private pointerDownCB = (event :React.PointerEvent<HTMLDivElement>)=>{
        //如果resizing是true，那么我们就不要做任何窗口层级关系的调整，主组件已经做调整了
        if(!this.resizing && event.buttons !== 2){
            if(
                (globalState.hasKey("Alt") || event.target === this.outerDivRef.current)
             && event.isPrimary
            ){
                this.props.onFocus();
                this.props.requestToTop();
                this.setState({moving: true});
                this.deltaTop = event.clientY - this.props.top;
                this.deltaLeft = event.clientX - this.props.left;
                document.body.classList.add("moving");
                document.addEventListener("pointermove", this.pointerMoveCB);
                document.addEventListener("pointerup", this.pointerUpCB);
            }
            else this.props.requestZIndex();
        }
    }
    private pointerMoveCB = (event :PointerEvent)=>{
        if(this.state.moving){
            this.props.requestUpdateAttachedStatus();
            const
                {height, width} = this.props,
                //计算坐标
                checkedBox = checkPos({top: event.clientY - this.deltaTop, left: event.clientX - this.deltaLeft}, {height, width});
            this.props.updateTL(checkedBox.top, checkedBox.left);
        }
    }
    private pointerUpCB = (e: any)=>{
        if(this.state.moving){
            this.props.onUnFocus();
            this.props.requestFallZIndex();
            this.setState({moving: false});
            document.body.classList.remove("moving");
            document.removeEventListener("pointermove", this.pointerMoveCB);
            document.removeEventListener("pointerup", this.pointerUpCB);
        }
    }
//#endregion
    private contextMenuCB = (event :React.MouseEvent<HTMLDivElement>)=>{
        if(event.target === this.outerDivRef.current){
            event.preventDefault();
            
        }
    }
//#region Render
    render() :React.ReactNode{
        return(
            <div
                className={`${styles.window}${this.props.noWrapperUI ?"": ` ${styles.windowUI}`}${this.props.minimized ? ` ${styles.windowHidden}`:""}${this.state.moving || this.resizing || this.props.hoveredInTask || (this.state.hovered && !this.state.focusingWithIn) ? ` ${styles.hoverWindow}`:""}${this.props.attached_t ? ` ${styles.attached_t}`:""}${this.props.attached_b ? ` ${styles.attached_b}`:""}${this.props.attached_l ? ` ${styles.attached_l}`:""}${this.props.attached_r ? ` ${styles.attached_r}`:""}`}
                style={{
                    top: `${this.props.top}px`,
                    left: `${this.props.left}px`,
                    height: `${this.props.height}px`,
                    width: `${this.props.width}px`,
                    cursor: this.state.moving ? "grabbing" : "grab",
                    zIndex: this.props.zIndex
                }}
                onPointerDown={this.pointerDownCB}
                onPointerEnter={()=>this.setState({hovered: true})}
                onPointerLeave={()=>this.setState({hovered: false})}
                onFocus={this.props.onFocus}
                onBlur={()=>{if(!this.state.moving){this.props.onUnFocus();}}}
                onContextMenu={this.contextMenuCB}
                ref={this.outerDivRef}>
                {/*this.props.noWrapperUI ? null : <DebugPanel data={this.props.id} />*/}
                <div
                    className={styles.windowInner}
                    onPointerEnter={()=>this.setState({focusingWithIn: true})}
                    onPointerLeave={()=>this.setState({focusingWithIn: false})}>
                    {this.state.onError ?
                        <div className={styles.error}>
                            <div>{i18n.current.errors.windowGeneric}</div>
                        </div>
                      : this.props.children
                    }
                </div>
                {this.props.noWrapperUI ? null : <Resizer {...this.props.resizerAPIs} startResize={this.startResize} updateResize={this.checkPos_updateAttachWhileResize} endResize={this.endResize} />}
            </div>
        );
    }
//#endregion
}