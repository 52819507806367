import React, { Component as Cp, ReactElement } from "react";
import mainStyles from "../../css/main.module.css";
import styles from "./Task.module.css";
import { rect } from "../../utils/zindex";
import { overlay } from "../Oinkedit";
export interface taskConfig{
    label :string;
    focusingInWindow :boolean;
    minimized :boolean;
    taskMoving :boolean;
    children :React.ReactNode;
}
export interface taskAPIs{
    hideWindow :()=>void;
    showWindow :()=>void;
    hoverInTask :()=>void;
    dehoverInTask :()=>void;
    startMove :()=>void;
    endMove :()=>void;
}
export type taskProps = Immutable<taskAPIs & taskConfig>;
export type taskInstance = ReactElement<taskProps, React.JSXElementConstructor<taskProps>>;
type State = Immutable<{
    moving :boolean;
}>;
export default class Task extends Cp<taskProps, State>{
    private innerRef :React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    private deltaTop :number = 0;
    private deltaLeft :number = 0;
    private overlayCreated :boolean = false;
    private overlayID: string = "";
    public hovered :boolean = false;
    constructor(props :taskProps){
        super(props);
        this.state = {moving: false};
    }
//#region 移动逻辑
    pointerDownCB = (event :React.PointerEvent<HTMLLIElement>)=>{
        if(event.isPrimary){
            this.setState({moving: true});
            document.body.classList.add("moving");
            document.addEventListener("pointermove", this.pointerMoveCB);
            document.addEventListener("pointerup", this.pointerUpCB);
            const top = rect(this.innerRef.current!, "ft"), left = rect(this.innerRef.current!, "fl");
            //console.log(top, left);
            this.deltaTop = event.clientY - top;
            this.deltaLeft = event.clientX - left;
        }
    }
    pointerMoveCB = (event :PointerEvent)=>{
        if(this.state.moving){
            if(!this.props.taskMoving) this.props.startMove();
            //createOverlay算是半个异步方法，我们自己用一个完全同步的flag，防止极端情况下出现多个Overlay
            if(!this.overlayCreated){
                this.overlayCreated = true;
                this.overlayID = overlay.current!.createOverlay({
                    children: <div className={`${mainStyles.noselect} ${mainStyles.nodrag}`} style={{
                        position: "fixed", fontSize: ".9rem", wordBreak: "keep-all",
                        top: event.clientY - this.deltaTop,
                        left: event.clientX - this.deltaLeft
                    }}>{this.props.label}</div>,
                    noWrapperUI: true,
                    cursor: "grabbing"
                });
            }
            else if(this.overlayID !== "") overlay.current!.refreshOverlay(this.overlayID, {
                children: <div style={{
                    position: "fixed", fontSize: ".9rem", wordBreak: "keep-all", whiteSpace: "pre",
                    top: event.clientY - this.deltaTop,
                    left: event.clientX - this.deltaLeft
                }}>{this.props.label}</div>,
                noWrapperUI: true,
                cursor: "grabbing"
            });
        }
    }
    pointerUpCB = ()=>{
        if(this.state.moving){
            this.setState({moving: false});
            this.props.endMove();
            document.body.classList.remove("moving");
            document.removeEventListener("pointermove", this.pointerMoveCB);
            document.removeEventListener("pointerup", this.pointerUpCB);
            overlay.current!.removeOverlay(this.overlayID);
            this.overlayID = "";
            this.overlayCreated = false;
        }
    }
//#endregion
    render() :React.ReactNode{
        return(
            <li
                onClick={()=>{
                    if(this.props.minimized) this.props.showWindow();
                    else this.props.hideWindow();
                }}
                onPointerDown={this.pointerDownCB}
                onPointerEnter={(event :React.PointerEvent<HTMLLIElement>)=>{if(event.isPrimary) this.props.hoverInTask();}}
                onPointerLeave={this.props.dehoverInTask}
                className={`${styles.li}${this.props.minimized ? ` ${styles.windowMinimized}` : ""}${this.props.focusingInWindow ? ` ${styles.focus}` : ""}`}
                style={{cursor: this.state.moving ? "grabbing" : "grab"}}>
                <div ref={this.innerRef}>{this.props.label}</div>
            </li>
        );
    }
}