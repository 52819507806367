import { overlay } from "../../sections/Oinkedit";
export default function openBook(){
    const overlayInstance = overlay.current!.createOverlay({
        children: <OpenBookOverlay />,
        allowClose: true,
        cover: true,
        animate: true,
        darken: true,
    });
}
function OpenBookOverlay(){
    return(
        <div>
            jjj
        </div>
    );
}