import React, { Component as Cp, ErrorInfo } from "react";
import styles from "./Overlay.module.css";
import { overlay } from "../Oinkedit";
import i18n from "../../i18n";
/**创建新的overlay时需要向父组件传入的参数*/
export interface overlayConstruct{
    /**将界面调暗，仅影响颜色*/
    darken? :boolean;
    /**阻止用户与下方元素交互*/
    cover? :boolean;
    /**仅当cover为true时才可用：允许用户点击遮盖时关闭*/
    allowClose? :boolean;
    /**尝试关闭时执行的回调函数。可以通过控制返回值决定是否关闭：返回true则关闭，false则不关闭。
     * 仅在其他条件都成功的情况下才会调用这个函数进行检查*/
    shouldClose? :((props :overlayConfig)=>boolean) | null;
    /**确定关闭并且已经调用了关闭API后的最后一个执行*/
    postClose? :((props :overlayConfig)=>void) | null;
    /**css光标字符串*/
    cursor? :string;
    /**是否显示弹出动画*/
    animate? :boolean;
    /**是否使用默认的窗口包装子元素*/
    noWrapperUI? :boolean;
    children :React.ReactNode;
}
/**需要传给overlay本身的参数*/
type overlayConfig = Required<overlayConstruct> & {
    id :string;
};
/**overlay总描述对象*/
export type overlayDesc = Immutable<overlayConfig>;
/**overlay暂时没有什么要和父组件沟通的，所以这里先留空占位*/
export type overlayAPIs = {};
type overlayProps = Immutable<overlayAPIs & overlayConfig>;
type State = Immutable<{
    onError :boolean;
}>;
export default class Overlay extends Cp<overlayProps, State>{
    containerRef :React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    pointerDownTarget? :EventTarget;
    constructor(props :overlayProps){
        super(props);
        this.state = {onError: false};
    }
//#region 错误边界
    //吐血的是，这里ts不能支持类型了，我们得翻文档写
    static getDerivedStateFromError(nextProps: Readonly<overlayProps>, prevState: State) :Partial<State> | null{
        return{onError: true};
    }
    componentDidCatch(error :Error, errorInfo :ErrorInfo){
        console.error(error, errorInfo);
    }
//#endregion
    pointerDownCB = (event :React.PointerEvent<HTMLElement>)=>{
        this.pointerDownTarget = event.target;
    }
    pointerUpCB = (event :React.PointerEvent<HTMLElement>)=>{
        if(
            this.props.allowClose
         && this.pointerDownTarget === event.target
         && event.target === this.containerRef.current!
         && (!this.props.shouldClose || this.props.shouldClose(this.props))
        ){
            overlay.current!.removeOverlay(this.props.id);
            if(this.props.postClose) this.props.postClose(this.props);
        }
        else if(!this.props.allowClose){
            //todo:做点动画，比如弹动窗口，或者像Windows®一样突然让用户耳机爆炸
            console.log(`${this.props.id} 不允许用户自行关闭。请在控制台调用${"`"}overlay.current.removeOverlay("${this.props.id}")${"`"}。`);
        }
    }
    render() :React.ReactNode{
        return(
            <div className={`${styles.overlay}${this.props.noWrapperUI ? "" : ` ${styles.overlayWrap}`}${this.props.animate ? ` ${styles.animate}`:""}${this.props.darken ? ` ${styles.darken}`:""}${this.props.cover ? ` ${styles.cover}`:""}`}
                style={{
                    cursor: this.props.cursor === "" ? "unset" : this.props.cursor 
                }}
                onPointerDown={this.pointerDownCB}
                onPointerUp={this.pointerUpCB}
                ref={this.containerRef}
            >
            {this.state.onError ?
                <div className={styles.error}>
                    <div>{i18n.current.errors.overlayGeneric}</div>
                </div>
              : this.props.children
            }</div>
        );
    }
}