const zh_hant = {
    taskbar: {
        customText: "按兩下此處来編輯格言",
        timeMaster: {
            title: "任務管理"
        },
        noLabel: "無標題",
    },
    contentEditor: {
        placeholder: "開始創作……"
    },
    start: {
        titleTip: "選單",
    },
    background: {
        tips: [
            <div key="menu">打開主選單　　按下工作列左側 Logo</div>,
            <div key="external">打開外部檔案　　<kbd>Ctrl</kbd> + <kbd>O</kbd></div>,
        ],
    },
    errors: {
        rootElNotFound: {
            main: "找不到用於加載應用的根 HTML DOM元素。",
            causedByOriginEl: "無法在檔案中找到 ID 為 `oinkedit` 的元素。如果您使用自定義元素，請確認是否將 `window.oeRoot` 設定為該元素的 ID。",
            causedByCustomEl: "無法在檔案中找到自定義元素。如果您不使用自定義元素，請確認 `window.oeRoot` 是否被污染。"
        },
        windowGeneric: "該視窗内應用出現了一個錯誤並已崩潰。嘗試重新啟動這個應用。",
        overlayGeneric: "出現了一個錯誤。嘗試重新打開彈窗。",
        intrinsicApps: {
            indexer: {
                noSuchBook: "未找到該 ID 對應的書籍。"
            }
        }
    },
    debug: {
        skipSW_Electron: "桌面版，跳過 Service Worker 注册",
        skipSW_Dev: "開發模式，跳過 Service Worker 注册",
        lipsum_crash: "人造崩潰，無需理會此錯誤",
    },
} as const;
export default zh_hant;