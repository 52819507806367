import React, { Component as Cp } from "react";
import i18n from "../../i18n";
interface State{
    date :Date;
}
/**@once*/
export default class TimeMaster extends Cp<{}, State>{
    id :number = 0;
    constructor(props :{}){
        super(props);
        this.state = {date: new Date()};
    }
    componentDidMount() :void{
        const id = setInterval(()=>{
            this.setState({date: new Date()});
        }, 1000);
        this.id = id as unknown as number;
    }
    componentWillUnmount() :void{
        clearInterval(this.id);
    }
    render() :React.ReactNode{
        const d = this.state.date;
        return(
            <div title={i18n.current.taskbar.timeMaster.title} style={{
                color: "var(--c-grey)",
                cursor: "pointer",
            }}>{/*todo: onclick={}*/}{d.getFullYear()}.{d.getMonth() + 1}.{d.getDate()} {d.getHours()}:{d.getMinutes().toString().padStart(2, "0")}</div>
        );
    }
}