const a = document.addEventListener;

//#region 是否按下鼠标或相当于鼠标的东西
let pointerDown :PointerEvent | null = null;
a("pointerdown", event=>{
    pointerDown = event;
});
a("pointerup", event=>{
    pointerDown = event;
});
a("pointercancel", event=>{
    pointerDown = event;
});
//#endregion

//#region 用户基本信息
const OS = (()=>{
    const raw = navigator.platform;
    if(raw.includes("Mac")) return "Mac";
    else if(raw.includes("Win")) return "Win";
    else if(raw.includes("iPad")) return "iPad";
    else if(raw.includes("Linux")) return "Linux";
    else return "Unknown";
})();
//#endregion

//#region 目前按下的键盘按键
let currentKeys :[KeyboardEvent["key"], KeyboardEvent["code"]][] = [];
a("keydown", event=>{
    let exists = false;
    for(let i = 0; i < currentKeys.length; i++) if(currentKeys[i][0] === event.key && currentKeys[i][1] === event.code){
        exists = true;
        break;
    }
    if(!exists) currentKeys.push([event.key, event.code]);
});
a("keyup", event=>{
    for(let i = 0; i < currentKeys.length; i++)
        if(currentKeys[i][0] === event.key && currentKeys[i][1] === event.code)
            currentKeys.splice(i, 1);
});
a("blur", ()=>{
    currentKeys = [];
});
//Oinkedit全局操控Alt/Option键，可以直接屏蔽掉默认操作
a("keydown", event=>{
    if(event.key === "Alt"){
        event.preventDefault();
    }
});
//Firefox很独特，keyup时也会触发菜单
a("keyup", event=>{
    if(event.key === "Alt"){
        //console.log("a");
        event.preventDefault();
    }
});
//#endregion
const globalState = {
    get pointerDown(){
        return pointerDown;
    },
    hasKey(key :KeyboardEvent["key"]) :boolean{
        for(let i = 0; i < currentKeys.length; i++) if(currentKeys[i][0] === key) return true;
        return false;
    },
    hasCode(code :KeyboardEvent["code"]) :boolean{
        for(let i = 0; i < currentKeys.length; i++) if(currentKeys[i][1] === code) return true;
        return false;
    },
    get userInfo(){
        return{OS};
    },
};
export default globalState;