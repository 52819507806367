import React, { Component as Cp } from "react";
import * as Switch from "@radix-ui/react-switch";
import styles from "./ConfigModifierComp.module.css";
import mainStyles from "../../css/main.module.css";
import { randoma2z029 } from "../../utils/general";
export default class ConfigModifierComp extends Cp<{}>{
    render() :React.ReactNode{
        return(<>
            <SettingUnit id="666">测试</SettingUnit>
            <SettingUnit id="666">测试</SettingUnit>
            <SettingUnit id="666">测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试</SettingUnit>
        </>);
    }
}
type settingUnitProps = Immutable<{
    id :string;
    children :React.ReactNode;
}>;
function SettingUnit(props :settingUnitProps){
    const id = `settingUnit_${props.id}_${randoma2z029(5)}`;
    return(
        <label htmlFor={id} className={`${styles.settingUnitWrapper} ${mainStyles.nodrag} ${mainStyles.noselect}`}>
            {props.children}
            <Switch.Root className={styles.switchRoot} id={id}>
                <Switch.Thumb className={styles.switchThumb} />
            </Switch.Root>
        </label>
    );
}