const zh_ac = {
    taskbar: {
        customText: "击此再以辑此箴言",
        timeMaster: {
            title: "览诸务"
        },
        noLabel: "无题",
    },
    contentEditor: {
        placeholder: "始著于此……"
    },
    start: {
        titleTip: "菜单",
    },
    background: {
        tips: [
            <div key="menu">启大菜单　　击公务架左侧之识记</div>,
            <div key="external">取书自域外之牒　　齐击 <kbd>Ctrl</kbd> 与 <kbd>O</kbd></div>,
        ],
    },
    errors: {
        rootElNotFound: {
            main: "寻载程式之根而无所得。",
            causedByOriginEl: "牒中无素名 `oinkedit`。若自定素，请立其名于 `window.oeRoot`。",
            causedByCustomEl: "牒中无自定之素。若非自定素，请保 `window.oeRoot` 不染污。"
        },
        windowGeneric: "此程式有误，已坏。请试而复启之。",
        overlayGeneric: "有误。请试而复启之。",
        intrinsicApps: {
            indexer: {
                noSuchBook: "循志，未能得其书。"
            }
        }
    },
    debug: {
        skipSW_Electron: "程式行于本地，不征代理之役",
        skipSW_Dev: "适逢测试之况，不征代理之役",
        lipsum_crash: "吾使其坏也，可不顾此误",
    },
} as const;
export default zh_ac;