import React, { useState } from "react";
import mainStyles from "../css/main.module.css";
import styles from "./ButtonBase.module.css";
export type ButtonBaseProps = Immutable<{
    styles? :{
        backgroundColor? :string;
        hoverBackgroundColor? :string;
        activeBackgroundColor? :string;
        disableBackgroundColor? :string;
        otherStyles? :React.CSSProperties;
    };
    height? :number | string;
    width? :number | string;
    disabled? :boolean;
    click? :React.MouseEventHandler<HTMLButtonElement>;
    pointerDown? :React.PointerEventHandler<HTMLButtonElement>;
    pointerUp? :React.PointerEventHandler<HTMLButtonElement>;
    pointerCancel? :React.PointerEventHandler<HTMLButtonElement>;
    hover? :React.PointerEventHandler<HTMLButtonElement>;
    deHover? :React.PointerEventHandler<HTMLButtonElement>;
    children? :React.ReactNode;
}>;
export default function ButtonBase(props :ButtonBaseProps){
    const
        [isHover, setIsHover] = useState(false),
        [isActive, setIsActive] = useState(false);
    return(
        <button
            className={`${styles.button} ${mainStyles.noselect} ${mainStyles.nodrag}`}
            onPointerEnter={(event :React.PointerEvent<HTMLButtonElement>)=>{
                setIsHover(true);
                if(props.hover) props.hover(event);
            }}
            onPointerLeave={(event :React.PointerEvent<HTMLButtonElement>)=>{
                setIsHover(false);
                if(props.deHover) props.deHover(event);
                setIsActive(false);
            }}
            onPointerDown={(event :React.PointerEvent<HTMLButtonElement>)=>{
                setIsActive(true);
                if(props.pointerDown) props.pointerDown(event);
            }}
            onClick={props.click}
            onPointerUp={(event :React.PointerEvent<HTMLButtonElement>)=>{
                setIsActive(false);
                if(props.pointerUp) props.pointerUp(event);
            }}
            onPointerCancel={(event :React.PointerEvent<HTMLButtonElement>)=>{
                setIsActive(false);
                if(props.pointerCancel) props.pointerCancel(event);
            }}
            disabled={props.disabled}
            style={{
                height: props.height,
                width: props.width,
                backgroundColor: getBackgroundColor(),
                ...props.styles?.otherStyles
            }}
        ><div className={styles.div}>{props.children}</div></button>
    );
    function getBackgroundColor() :string | undefined{
        //覆盖浏览器默认样式表 ?? "transparent"
        if(props.disabled) return props.styles?.disableBackgroundColor ?? "transparent";
        else{
            if(isActive) return props.styles?.activeBackgroundColor ?? "transparent";
            else if(isHover) return props.styles?.hoverBackgroundColor ?? "transparent";
            else return props.styles?.backgroundColor ?? "transparent";
        }
    }
}