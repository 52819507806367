import globalState from "../dataProviders/globalState";
import { export_ } from "../sections/Oinkedit";
import { isJSONObject } from "./general";
/**将任何长度单位或 css 变量转换为像素值*/
const getPx_test = document.createElement("div"), originStyle = "position:absolute;top:-12914rem;left:-12914rem;";
getPx_test.setAttribute("style", originStyle);
export function mountGetPx(){
    document.getElementById((window as anyObject).oeRoot || "oinkedit")!.appendChild(getPx_test);
}
export_({getPx});
export function getPx(value :number | string) :number{
    if(value === 0) return 0;
    getPx_test.setAttribute("style", originStyle + `height:${value}`);
    const result = parseFloat(getComputedStyle(getPx_test).height);
    getPx_test.setAttribute("style", originStyle);
    return result;
}
/**获取任务栏高宽*/
export function getTaskBarHW(){
    return{
        height: getPx("var(--cf-tbtaskbar)"),
        width: getPx("var(--cf-lrtaskbar)")
    };
}
/**从坐标信息得到窗口四边圆角是否应该去除以显示“附着”效果*/
export function getAttachedStatus(tlhw :Box){
    const taskbar = getTaskBarHW(), {clientHeight, clientWidth} = document.body;
    return{
        attached_t: tlhw.top <= taskbar.height,
        attached_b: tlhw.top + tlhw.height >= clientHeight,
        attached_l: tlhw.left <= taskbar.width,
        attached_r: tlhw.left + tlhw.width >= clientWidth
    };
}
/**测试一组坐标是否超出浏览器窗口界限，如果超出则拉回至合法坐标
 * 
 * 这个方法是处理按住 `Alt` 键不允许拖出窗口的唯一地方！
 * @param changesHW 将宽高拉回至合法值而不是将坐标拉回至合法值*/
export function checkPos(tl :TL, hw :HW, changesHW? :boolean) :Box{
    const taskbar = getTaskBarHW(),
          padding = getWindowPadding(),
          {clientHeight, clientWidth} = document.body,
          result :Box = {...tl, ...hw},
          {top, left, height, width} = result;
    //按住Alt或Mac:Option时，会限制窗口的任何部分拖出视口
    if(globalState.hasKey("Alt")){
        //由于Resizer只支持向右和向下扩展，只需要在这两边进行判断
        if(top >= clientHeight - height){
            if(changesHW === true) result.height = clientHeight - top;
            else result.top = clientHeight - height;
        }
        if(left >= clientWidth - width){
            if(changesHW === true) result.width = clientWidth - left;
            else result.left = clientWidth - width;
        }
        if(top <= taskbar.height) result.top = taskbar.height;
        if(left <= taskbar.width) result.left = taskbar.width;
    }
    else{ //否则允许窗口拖动至只剩padding在视口内
        //console.log(top, taskbar.height , height , padding.tb);
        if(top >= clientHeight - padding.tb) result.top = clientHeight - padding.tb;
        if(left >= clientWidth - padding.lr) result.left = clientWidth - padding.lr;
        if(top <= taskbar.height - height + padding.tb) result.top = taskbar.height - height + padding.tb;
        if(left <= taskbar.width - width + padding.lr) result.left = taskbar.width - width + padding.lr;
    }
    //console.log(source, result, {...tl, ...hw});
    return result;
}
/**由于使用了魔鬼盒，需要对外公开窗口的 `padding` 方便计算窗口内部尺寸*/
export function getWindowPadding(){
    return{
        //应与实际 padding 保持一致
        lr: getPx("1rem"),
        tb: getPx(".8rem"),
    };
}
/**css 获取与设置*/
export function css(el :HTMLElement, property :string | anyObject, value? :string) :string{
    if(typeof property == "object"){
        //两个if最好不合并，因为这样可以先确定property的类型，少一个断言
        if(isJSONObject(property))for(let k in property) el.style.setProperty(k, property[k]);
    }
    else{
        if(value !== undefined) el.style.setProperty(property, value);
        //本来需要转驼峰才能正常，不知为何不转也可以
        else return (getComputedStyle(el) as anyObject)[property];
    }
    //万能兜底
    return value!;
}
/**快速获取z-index*/
export function getZ(el :HTMLElement){return parseInt(el.style.zIndex);}
/**修改z-index*/
export function setZ(el :HTMLElement, index :number){return css(el, "z-index", index + "");}