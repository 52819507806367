/* Oinkedit ©LJM12914. https://github.com/openink/oinkedit
 * Next-generation tool for narrative creative writing.
 * Licensed under AGPL-3.0 license AND OTHER CLAUSES IN README.md.
 */
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import Oinkedit, { export_ } from "./sections/Oinkedit";
import "./css/main.css";
import "./css/main.body.css";
import "./css/main.scrollbar.css";
import "./css/main.basicElements.css";
import iniStorage, { dataBase } from "./lifecycle/iniStorage";
import meta from "./dataProviders/meta";
import { generateManifest } from "./lifecycle/manifest";
import { startLeavePrompt } from "./lifecycle/leavePrompt";
import i18n from "./i18n";
import ContentEditor from "./applications/contentEditor/ContentEditor";
import { mountGetPx } from "./utils/css";

//note:oeRoot是给嵌入Oinkedit的网页准备的hook
interface windowC extends Window{oeRoot? :string;}
const windowChanged = window as windowC, rootEl = document.getElementById(windowChanged.oeRoot || "oinkedit")!;
if(!rootEl) throw new ReferenceError(`${i18n.current.errors.rootElNotFound.main}\n${windowChanged.oeRoot ? i18n.current.errors.rootElNotFound.causedByCustomEl : i18n.current.errors.rootElNotFound.causedByOriginEl}`);
const appRoot = createRoot(rootEl), oinkedit = React.createRef<Oinkedit>();
function reload(){
    appRoot.render(<StrictMode><Oinkedit key={Date.now()} ref={oinkedit} /></StrictMode>);
    setTimeout(mountGetPx, 0);
}
//启动界面
reload();
//初始化存储区
iniStorage();
//需要动态生成manifest，因为可能可以允许用户修改manifest的某些部分。
generateManifest();
//启动网页关闭提示
startLeavePrompt();

export {oinkedit, reload, rootEl};

//以下 DEV ONLY
export_({React, dataBase, oinkedit, reload});
if(meta.dev === true){
    setTimeout(()=>{
        setTimeout(()=>{
            new ContentEditor("iiiii");
        }, 200);
    }, 0);
}