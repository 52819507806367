import React, { Component as Cp } from "react";
import Logo from "../../generalComponents/Logo";
import i18n from "../../i18n";
import VerticalList from "../../generalComponents/VerticalList";
import { overlay } from "../Oinkedit";
import { oinkedit } from "../..";
import BookHistory from "../../otherUI/BookHistory";
import meta, { Lipsum } from "../../dataProviders/meta";
import ButtonBase from "../../generalComponents/ButtonBase";
import createBook from "../../lifecycle/book/createBook";
import openBook from "../../lifecycle/book/openBook";
import ConfigModifier from "../../applications/configModifier/ConfigModifier";
/**@once*/
export default class MenuOpener extends Cp{
    clickCB = ()=>{
        const buttonStyles :{
            backgroundColor? :string;
            hoverBackgroundColor? :string;
            activeBackgroundColor? :string;
            disableBackgroundColor? :string;
            otherStyles? :React.CSSProperties;
        } = {
            backgroundColor: "var(--c-black-tra-2)",
            hoverBackgroundColor: "var(--c-black-tra-1)",
            activeBackgroundColor: "var(--c-black-tra-4)",
            disableBackgroundColor: "var(--c-black-tra-2)",
            otherStyles: {
                borderRadius: ".3rem",
            }
        };
        const menu = overlay.current!.createOverlay({
            allowClose: true,
            cover: true,
            darken: false,
            animate: false,
            children: <div style={{
                position: "fixed",
                top: "var(--cf-tbtaskbar)",
                left: "var(--cf-lrtaskbar)",
                display: "flex",
                flexFlow: "row nowrap",
                gap: "1rem",
            }}>
                <VerticalList styles={{
                    display: "flex",
                    flexFlow: "column nowrap",
                    gap: ".2rem",
                    width: "5rem",
                }}>
                    <ButtonBase click={createBook} styles={buttonStyles}>新建</ButtonBase>
                    <ButtonBase click={openBook} styles={buttonStyles}>打开</ButtonBase>
                    <ButtonBase styles={buttonStyles}>另存为...</ButtonBase>
                    <ButtonBase click={()=>{
                        new ConfigModifier();
                        overlay.current!.removeOverlay(menu);
                    }} styles={buttonStyles}>设置</ButtonBase>
                    {meta.dev ? <>
                        <div onClick={()=>{
                            oinkedit.current!.createWindow({
                                label: "测试窗口",
                                top: 0,
                                left: 0,
                                children: <Lipsum />,
                                height: 0,
                                width: 0
                            });
                            overlay.current!.removeOverlay(menu);
                        }}>测试窗口</div>
                        <div onClick={()=>{
                            overlay.current!.createOverlay({
                                children: <Lipsum />,
                                cover: true,
                                darken: true,
                                allowClose: true,
                                animate: true
                            });
                        }}>测试弹窗</div>
                    </> : null}
                </VerticalList>
                <BookHistory />
            </div>
        });
    }
    contextMenuCB = (event :React.MouseEvent)=>{
        event.preventDefault();
        document.body.requestFullscreen();
    }
    render() :React.ReactNode{//todo:hover&focus效果
        return(
            <div style={{
                margin: ".25rem",
                cursor: "pointer",
            }}
            onClick={this.clickCB}
            onContextMenu={this.contextMenuCB}>
                <Logo size="2.4rem" title={i18n.current.start.titleTip} />
            </div>
        );
    }
}