import React, { Component as Cp } from "react";
import i18n from "../i18n";
import { constantize } from "../utils/general";
const meta = {
    /**版本，允许各种非规范词*/
    version: "0.10 pre1",
    /**严格规范版本，用于检查更新，并应与 `package.json` 保持一致*/
    regulatedVersion: "0.9.0",
    /**是否为开发版本，这个 flag 控制：
     * 
     * 1. Oinkedit 的程序内显示
     * 2. 跳过 serviceworker 注册*/
    dev: true,
    /**这个 flag 的意义是防止用户把未公开或尚未能使用的版本当作正常版本使用
     * 
     * 打开后会显著地提示用户并限制用户每次使用时间*/
    isPreVersion: false,
    /**限时，单位为秒*/
    timeLimit: 120,
    /**版本更新日志，计划从1.0开始*/
    whatsnew: {

    },
    /**题诗，计划从1.0开始*/
    themePoem: "相与枕藉乎舟中，不知东方之既白。", //©1082 苏轼（苏东坡）
} as const;
//示例文本，不能constantize，否则用不了了
const Lipsum = class extends Cp<{}, {c :boolean}>{
    constructor(props :{}){
        super(props);
        this.state = {c: false};
    }
    render() :React.ReactNode{
        if(this.state.c) throw new Error(i18n.current.debug.lipsum_crash);
        return(
            <div> 
               <button onClick={()=>{
                    this.setState({c: true});
                }}>崩溃测试</button>
                <div>七月流火，九月授衣。一之日觱发，二之日栗烈。无衣无褐，何以卒岁？</div>
                <div>三之日于耜，四之日举趾。同我妇子，馌彼南亩。田畯至喜。</div>
                <div key="3">七月流火，九月授衣。春日载阳，有鸣仓庚。女执懿筐，遵彼微行，爰求柔桑。</div>
                <div key="4">春日迟迟，采蘩祁祁。女心伤悲，殆及公子同归。</div>
                <div key="5">七月流火，八月萑苇。蚕月条桑，取彼斧斨。以伐远扬，猗彼女桑。</div>
                <div key="6">七月鸣鵙，八月载绩。载玄载黄，我朱孔阳，为公子裳。</div>
                <div key="7">四月秀葽，五月鸣蜩。八月其获，十月陨萚。一之日于貉，取彼狐狸，为公子裘。</div>
                <div key="8">二之日其同，载缵武功。言私其豵，献豜于公。</div>
                <div key="9">五月斯螽动股，六月莎鸡振羽。七月在野，八月在宇，九月在户，十月蟋蟀，入我床下。</div>
                <div key="10">穹窒熏鼠，塞向墐户。嗟我妇子，曰为改岁，入此室处。</div>
                <div key="11">六月食郁及薁，七月亨葵及菽。八月剥枣，十月获稻。为此春酒，以介眉寿。</div>
                <div key="12">七月食瓜，八月断壶，九月叔苴，采荼薪樗。食我农夫。</div>
                <div key="13">九月筑场圃，十月纳禾稼。黍稷重穋，禾麻菽麦。嗟我农夫，我稼既同，上入执宫功。</div>
                <div key="14">昼尔于茅，宵尔索綯，亟其乘屋，其始播百谷。</div>
                <div key="15">二之日凿冰冲冲，三之日纳于凌阴。四之日其蚤，献羔祭韭。九月肃霜，十月涤场。</div>
                <div key="16">朋酒斯飨，曰杀羔羊，跻彼公堂。称彼兕觥：万寿无疆！</div>
            </div>
        );
    }
}
constantize(meta);
//元数据共享
Object.defineProperty(window, "oeMeta", {
    configurable: false,
    enumerable: true,
    writable: false,
    value: meta
});
export default meta;
export {Lipsum};